var indexSearchKeys = [{
  key: "boxname",
  label: "盲盒名称",
  placeholder: "请输入名称",
  required: false,
  rules: [],
  input: true
}, {
  key: "tokenid",
  label: "盲盒tokenid",
  placeholder: "请输入tokenid",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { indexSearchKeys };