var indexColumns = [{
  title: 'tokenId',
  dataIndex: 'tokenid',
  key: 'tokenid',
  width: '10%'
}, {
  title: '图片',
  dataIndex: 'image',
  key: 'image',
  width: '10%',
  scopedSlots: {
    customRender: 'image'
  }
}, {
  title: '盲盒名称',
  dataIndex: 'boxname',
  key: 'boxname',
  width: '10%'
}, {
  title: 'KNOWHERE链接',
  dataIndex: 'h5link',
  key: 'h5link',
  width: '20%',
  scopedSlots: {
    customRender: 'h5link'
  }
}, {
  title: 'NFTScan',
  dataIndex: 'tokenid',
  key: 'nftscan',
  width: '20%',
  scopedSlots: {
    customRender: 'nftscan'
  }
}, {
  title: '状态',
  dataIndex: 'state',
  key: 'state',
  width: '5%',
  scopedSlots: {
    customRender: 'state'
  }
}, {
  title: '持有地址',
  dataIndex: 'owner',
  key: 'owner',
  width: '10%'
}, {
  title: '持有用户',
  dataIndex: 'account',
  key: 'account',
  width: '10%'
}];
export { indexColumns };