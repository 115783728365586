var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", {
    ref: "dcBlindBoxForm",
    attrs: {
      labelCol: {
        style: "width: 150px"
      },
      model: _vm.form,
      rules: _vm.rules
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "一次性购买消耗",
      prop: "BlinkBoxCBK"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      autoComplete: "off",
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.BlinkBoxCBK,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "BlinkBoxCBK", $$v);
      },
      expression: "form.BlinkBoxCBK"
    }
  }), _vm._v(" CBK ")], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_r",
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.disabledLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确认")]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v("取消")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };